import { defineNuxtPlugin, useAppConfig } from "#imports";
export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useAppConfig();
  nuxtApp.hook("app:created", async () => {
    const isBundle = appConfig.__swiper.bundled;
    if (!isBundle) {
      (await import("swiper/element")).register();
      return;
    }
    (await import("swiper/element/bundle")).register();
  });
});
