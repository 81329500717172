<script setup lang="ts">
useHead({
  titleTemplate: 'Gerd Kaimer Bürgerstiftung Solingen - %s'
})
</script>

<template>
  <NuxtLayout>
    <v-app>
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>
