import { createVuetify } from 'vuetify'

import type { ThemeDefinition } from 'vuetify'
import { aliases, custom } from '@/helpers/customIcons'

// import '@mdi/font/css/materialdesignicons.css'
import '@/assets/scss/style.scss'

const solingenTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#ffd500', // #yellow
    secondary: '#004373', // #blue
    accent: '#7f7f7f' // #grey
  }
}

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: 'solingenTheme',
      themes: { solingenTheme }
    },
    icons: {
      defaultSet: 'custom',
      aliases,
      sets: {
        custom
      }
    },
    defaults: {
      VBtn: {
        style: 'text-transform: none; letter-spacing:0; border-radius: 10px;'
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
